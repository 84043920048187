import { RasaContext } from 'context'
import * as Flash from 'components/flash'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import React from 'react'
import './styles.scss'
import './stripo.scss'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { Loading } from 'components/loading'
import { DATE_FORMATS, DATE_TIME_FORMATS, EmailAttributes, getAttribute, getDateFormatOptions, STRIPO_TEMPLATE_DEFAULT_NAME } from './constants'
import { BaseClientEntity } from 'generic/baseClientEntity'
import { getAllTextLocationsOptions, getBannerImageLocationOptions } from 'shared_server_client/utils'
import { Dataset } from 'generic/dataset'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

type StripoState = {
  configs: any,
  communityId: string,
  emailLayoutId: string,
  emailLayout: any,
  isDirty: boolean,
  isLoading: boolean,
  isSaving: boolean,
  showSaveTemplateModal: boolean,
  templateName: string,
}

type StripoProps = GenericRedux.AllComponentProps<any>

// Hack to ensure we can type-safe access window.Stripo
declare const window: any;
declare const STRIPO_PLUGIN_ID: string;
declare const STRIPO_SECRET_KEY: string;
declare const RASA_ASSETS_CDN: string

const getAuthToken = (communityId, callback) => {
  const url: string = AjaxWrapper.getServerUrl() + `/stripo/auth/${communityId}`
  AjaxWrapper.xmlRequest(url, HttpMethod.GET, {}, function(data) {
    callback(JSON.parse(data).token)
  })
}

export class StripoEditorComponent extends RasaReactComponent<StripoProps, StripoState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  constructor(props: any) {
    super(props, 'stripoHtmlTemplates')
    this.state = {
      communityId: "",
      configs: {},
      emailLayout: {},
      emailLayoutId: "",
      isDirty: false,
      isLoading: false,
      isSaving: false,
      showSaveTemplateModal: false,
      templateName: STRIPO_TEMPLATE_DEFAULT_NAME,
    }
    this.getAndSaveTemplate = this.getAndSaveTemplate.bind(this)
    this.getRasaImageURL = this.getRasaImageURL.bind(this)
    this.setRasaConfigValue = this.setRasaConfigValue.bind(this)
    this.saveConfigs = this.saveConfigs.bind(this)
    this.loadTemplates = this.loadTemplates.bind(this)
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    this.sharedStore.getValue(SharedKeys.activeCommunity)
    .then((activeCommunity) => {
      this.setState({
        communityId: activeCommunity.communityId,
        emailLayoutId: activeCommunity.communityInfo.data.email_layouts.filter((s: any) => s.is_active)[0].id,
      }, () => {
        this.loadTemplates(this.state.communityId)
      })
    })
  }


  public render() {
    return <div className="stripo">
      <div>
        <div id="stripoSettingsContainer"><Loading size="64" /></div>
        <div id="stripoPreviewContainer"></div>
      </div>
      <div className="notification-zone"></div>
      <div id="externalSystemContainer">
        {this.renderSaveTemplateModal()}
        {this.state.isSaving ? <Loading size="32" />:
        <div>
          <button disabled={this.state.isLoading} id="saveButton" onClick={this.showTemplateModal} className="control-button">Save</button>
        </div>}
      </div>
    </div>
  }

  public getRasaImageURL = (imageUrl) => {
    const imageValidateEndpoint: string =  `${AjaxWrapper.getServerUrl()}/gallery/${this.state.communityId}/source`
    return AjaxWrapper.ajax(imageValidateEndpoint, HttpMethod.POST, {imageUrl})
  }

  public setRasaConfigValue = (key, value) => {
    this.setState({
      configs: {
        ...this.state.configs,
        [key]: value
      }
    })
  }

  private saveConfigs = () => {
    this.context.entityMetadata.getEntityObject('email_layout', this.state.communityId, this.state.emailLayoutId)
      .then((emailLayoutEntityObject: BaseClientEntity) => {
        Object.keys(this.state.configs).forEach((key) => {
          const value = this.state.configs[key]
          emailLayoutEntityObject.data[key] = value
        })
        emailLayoutEntityObject.data[EmailAttributes.stripoDefaultTemplateName] = this.state.templateName
        emailLayoutEntityObject.save()
      })
      .catch()
  }

  private getEmailLayoutInfo = () => {
    return this.context.entityMetadata.getEntityObject('email_layout').then(
      (entityObject: any ) => {
      return new Promise ((resolve, reject) => {
        entityObject.load(this.state.communityId, this.state.emailLayoutId).then(() => {
        this.setState({
          emailLayout: {
            ...entityObject.data,
          }
        }, () => {
          resolve(entityObject.data)
        })
      })
      })
    })
  }

  private getSections = (community) => {
    return new Dataset()
    .loadCommunityDataset('sections', community)
    .then((response) => {
      return this.normalizeSections(response[0] || [])
    })
  }

  private normalizeSections = (sections) => {
    const normalizedSections = sections.map((s) => ({
      description: s.name,
      key: s.name,
      value: s.name,
    }))
    if (sections.length) {
      normalizedSections.unshift({
        description: 'No section',
        key: 'No Section',
        value: '',
      })
    }
    return normalizedSections
  }

  private loadTemplates = (communityId) => {
    this.getEmailLayoutInfo().then((emailLayout: any) => {
      const url: string = AjaxWrapper.getServerUrl() + `/stripo/templates/${communityId}?templateName=${emailLayout[EmailAttributes.stripoDefaultTemplateName]}`
      Promise.all([
        AjaxWrapper.ajax(url, HttpMethod.GET, {}),
        getBannerImageLocationOptions(),
        getBannerImageLocationOptions(),
        getAllTextLocationsOptions(),
        this.getSections(communityId),
      ]).then(([template, bannerLocationOptions, squareLocationOptions, textLocationOptions, sections]) => {
        this.initPlugin(template, emailLayout, this.getRasaImageURL, this.setRasaConfigValue, bannerLocationOptions, squareLocationOptions, textLocationOptions, sections, this.state.communityId)
      })
    })
  }

  private renderSaveTemplateModal(): JSX.Element {
    return (
      <div>
        <Modal isOpen={this.state.showSaveTemplateModal}>
          <ModalHeader>
            Name Your Template
          </ModalHeader>
          <ModalBody className='save-template-modal-body'>
            <div>
                <input
                  className='template-name-input'
                  type="text"
                  value={this.state.templateName}
                  onChange={(e) => this.setState({templateName: e.target.value})}
                />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => this.saveTemplate()}>
              Save
            </Button>
            <Button onClick={() => this.setState({
              showSaveTemplateModal: false,
              templateName: STRIPO_TEMPLATE_DEFAULT_NAME
            })}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }

  private showTemplateModal = () => {
    this.setState({
      showSaveTemplateModal: true
    })
  }

  private saveTemplate = () => {
    this.setState({
      isSaving: true,
      showSaveTemplateModal: false,
    })
    window.StripoApi.getTemplate(this.getAndSaveTemplate)
    this.saveConfigs()
  }

  private getAndSaveTemplate = (html, css) => {
    //your save logic should be here
    const url: string = AjaxWrapper.getServerUrl() + `/stripo/templates/${this.state.communityId}`
    return AjaxWrapper.ajax(url, HttpMethod.POST, {
      html,
      css,
      filename: this.state.templateName,
    }).then((template) => {
      this.setState({
        isSaving: false,
      })
      this.context.store.dispatch(Flash.showFlashMessage('Template saved successfully.'))
    }).catch((error) => {
      this.setState({
        isSaving: false,
      })
      this.context.store.dispatch(Flash.showFlashMessage('Failed to save template. Please try again.'))
    })
  }

  private initPlugin(template, emailLayout, getRasaImageUrl, setRasaConfigValue, bannerLocationOptions, squareLocationOptions, textLocationOptions, sections, communityId) {
    const dateFormats = DATE_FORMATS
    if (!dateFormats.includes(getAttribute(emailLayout, EmailAttributes.headerDateFormat))) {
      dateFormats.push(getAttribute(emailLayout, EmailAttributes.headerDateFormat))
    }
    const dateTimeFormats = DATE_TIME_FORMATS
    if (!dateTimeFormats.includes(getAttribute(emailLayout, EmailAttributes.eventDateFormat))) {
      dateTimeFormats.push(getAttribute(emailLayout, EmailAttributes.eventDateFormat))
    }
    const apiRequestData = {
      emailId: 123,
      accountId: "000000",
    };
    const script = document.createElement('script');
    const controlsToRemove = [
      'messageAlignAppearanceControl',
      'generalBackgroundImageAppearanceControl',
      'headerBackgroundImageAppearanceControl',
      'footerBackgroundImageAppearanceControl',
      'headerContentBackgroundColorAppearanceControl',
    ]
    const MAX_EMAIL_WIDTH = 728
    script.id = 'stripoScript';
    script.type = 'text/javascript';
    script.src = 'https://plugins.stripo.email/static/latest/stripo.js';
    script.onload = function () {
      if (!window.Stripo ) {
        return
      }
      // eslint-disable-next-line no-undef
      window.Stripo.init({
        settingsId: 'stripoSettingsContainer',
        previewId: 'stripoPreviewContainer',
        codeEditorButtonId: 'codeEditor ',
        undoButtonId: 'undoButton',
        redoButtonId: 'redoButton',
        locale: 'en',
        html: template.html,
        css: template.css,
        apiRequestData,
        userFullName: 'Plugin Demo User',
        settingsPanelBlockSortFunc: (names: any[]) => {
          const rasaBlocks = names.filter((x) => x.startsWith('esd-extension'))
          const otherBlocks = names.filter((x) => !x.startsWith('esd-extension'))
          return [...rasaBlocks, ...otherBlocks]
        },
        versionHistory: {
          changeHistoryLinkId: 'changeHistoryLink',
        },
        "socialNetworks": [
          {
            "name": "twitter",
            "href": ""
          },
          {
            "name": "facebook",
            "href": ""
          },
          {
            "name": "linkedin",
            "href": ""
          },
          {
            "name": "instagram",
            "href": ""
          },
          {
            "name": "youtube",
            "href": ""
          }
        ],
        "extensions": [
          {
            "globalName": "HeaderExtension",
            "url": `${RASA_ASSETS_CDN}/stripo/rasa-stripo-extensions.js`,
          }, {
            "globalName": "BannerExtension",
            "url": `${RASA_ASSETS_CDN}/stripo/rasa-stripo-extensions.js`,
          }, {
            "globalName": "SquareExtension",
            "url": `${RASA_ASSETS_CDN}/stripo/rasa-stripo-extensions.js`,
          }, {
            "globalName": "ArticleExtension",
            "url": `${RASA_ASSETS_CDN}/stripo/rasa-stripo-extensions.js`,
          }, {
            "globalName": "EventExtension",
            "url": `${RASA_ASSETS_CDN}/stripo/rasa-stripo-extensions.js`,
          }, {
            "globalName": "FooterExtension",
            "url": `${RASA_ASSETS_CDN}/stripo/rasa-stripo-extensions.js`,
          }, {
            "globalName": "TextExtension",
            "url": `${RASA_ASSETS_CDN}/stripo/rasa-stripo-extensions.js`,
          }
       ],
       "rasaCallbacks": {
        getRasaImageUrl,
        setRasaConfigValue
      },
      emailLayout,
       "headerBlock": {
          "enabled": true,
          "brand_header_image_url": getAttribute(emailLayout, EmailAttributes.brandHeaderImageUrl),
          "brand_header_alt_text": getAttribute(emailLayout, EmailAttributes.brandHeaderAltText),
          "dateFormatOptions": getDateFormatOptions(dateFormats),
          "header_date_format": getAttribute(emailLayout, EmailAttributes.headerDateFormat),
          "show_subscribe_url_in_header": getAttribute(emailLayout, EmailAttributes.showSubscribeUrlInHeader),
          "show_unsubscribe_url_in_header": getAttribute(emailLayout, EmailAttributes.showUnsubscribeUrlInHeader),
       },
       "bannerBlock": {
          "enabled": true,
          "locationOptions": bannerLocationOptions,
        },
        "squareBlock": {
          "enabled": true,
          "locationOptions": squareLocationOptions,
        },
        "articleBlock": {
          "enabled": true,
          "sectionOptions": sections,
        },
        "eventBlock": {
          "enabled": true,
          "event_date_format": getAttribute(emailLayout, EmailAttributes.eventDateFormat),
          "dateTimeFormatOptions": getDateFormatOptions(dateTimeFormats),
          "sectionOptions": sections,
        },
        "footerBlock": {
          "enabled": true,
          "show_rasa_logo": getAttribute(emailLayout, EmailAttributes.showRasaLogo),
        },
        "textBlock": {
          "enabled": true,
          "locationOptions": textLocationOptions,
        },
        getAuthToken: (callback) => {
          getAuthToken(communityId, callback)
        }
      }, () => {
        const removeDefaultControl = (rawControls, controlNameToRemove) => rawControls.splice(rawControls.indexOf(rawControls.find(c => c.name === controlNameToRemove)), 1);
        window.StripoApi.initExternalSettingAppearanceControls = function(rawControls) {
          // Remove default Appearance 'Message alignment' control
          controlsToRemove.forEach(controlNameToRemove => removeDefaultControl(rawControls, controlNameToRemove));
          rawControls.find((x) => x.name === "emailWidthAppearanceControl").getMaxValue = () => { return MAX_EMAIL_WIDTH }
      }
      });
    };
    document.body.appendChild(script);
  }

}

export const StripoEditor = GenericRedux.registerNewComponent<any>(
  StripoEditorComponent,
  'stripo',
  {})
